import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
} from "reactstrap";

import axios from "axios";
import ReactMde from "react-mde";
import { useState, useEffect } from "react";
import {
  PERANGKAT_DAERAH,
  API_TIMEOUT,
  ANALISIS_JABATAN,
  POST_ANJAB,
} from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { GET_ANJAB } from "utils";
import { formatDate } from "utils/func";
import { toProperCase } from "utils/func";
import Success from "../assets/img/success.png";
import { downloadFile } from "utils/func";
import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { GET_PENGADUANALL } from "utils";
import { GET_TRACKPENGADUAN } from "utils";
import { formatDateTime } from "utils/func";
import { POST_TRACKPENGADUAN } from "utils";
import { domain } from "utils";

function Pengaduan() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();

  const [id_unit, setId_Unit] = useState("");
  const [urlFile1, setUrlFile1] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [catatan, setCatatan] = useState("");
  const [latest_status, setLatest_status] = useState("");
  const [progressAnjab, setProgressAnjab] = useState([]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Pengaduan");
      } else {
        history.push("/login");
      }
    }
    refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser) {
      ambilPengaduan();
    }
  }, [currentUser]);

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };

  const calculateDateDifference = (createdAt, status, tglStatus, model) => {
    if (!status) {
      const now = new Date();
      const createdAtDate = new Date(createdAt);

      // Menghitung perbedaan waktu dalam milidetik
      const differenceInTime = now - createdAtDate;

      // Mengonversi perbedaan waktu dari milidetik ke hari
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      );

      return differenceInDays;
    } else if (status && status == "Finish") {
      const now = new Date(tglStatus);
      const createdAtDate = new Date(createdAt);

      // Menghitung perbedaan waktu dalam milidetik
      const differenceInTime = now - createdAtDate;

      // Mengonversi perbedaan waktu dari milidetik ke hari
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      );

      return differenceInDays;
    } else if (status && status != "Finish") {
      const now = new Date();
      const createdAtDate = new Date(createdAt);

      // Menghitung perbedaan waktu dalam milidetik
      const differenceInTime = now - createdAtDate;

      // Mengonversi perbedaan waktu dari milidetik ke hari
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      );

      return differenceInDays;
    }
  };

  const ambilPengaduan = (id) => {
    axios({
      method: "post",
      url: GET_PENGADUANALL,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          if (currentUser.status == 1) {
            setTabelPerangkatDaerah(data);
          } else {
            const filter = data.filter((item) => {
              return item.id_unit == currentUser.idLokasi;
            });
            setTabelPerangkatDaerah(filter);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilAnjab = (id) => {
    axios({
      method: "get",
      url: GET_TRACKPENGADUAN + id,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setProgressAnjab(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleSubmit = async (event) => {
    if (id_unit && status && tanggal && catatan) {
      const formData = new FormData();
      formData.append("photo", dokumen);

      formData.append("idPengaduan", id_unit);
      formData.append("statusProses", status);
      formData.append("tanggalStatus", tanggal);

      formData.append("catatan", catatan);
      formData.append("idAdmin", currentUser.uid);

      await axios({
        method: "post",
        url: POST_TRACKPENGADUAN,
        timeout: API_TIMEOUT,
        data: formData,
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            swal("Failed", "ada kesalahan pada server", "error");
          } else {
            //BERHASIL
            swal("Success", "Berita berhasil diposting", "success");
            ambilPengaduan();
            ambilAnjab(id_unit);
            onModalClose();
          }
        })
        .catch((error) => {
          // ERROR
          swal("Failed", "Ada kesalahan pada server", "error");
        });

      setSaveLoading(false);
    } else {
      swal("Failed", "Ada data yang belum diinput", "error");
      setSaveLoading(false);
    }
  };

  const onModalClose = () => {
    setStatus("");
    setDokumen("");

    setTanggal("");
    setCatatan("");
    setModalOpen2(false);
  };

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Daftar Pengaduan Masyarakat</CardTitle>
                <Button
                  color="danger"
                  onClick={() => history.push("/admin/dokumenanjab")}
                >
                  Dokumen
                </Button>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Kategori</th>
                      <th>Model</th>
                      <th>Detail</th>
                      <th>Perangkat Daerah</th>
                      <th>Desa</th>
                      <th>Jumlah hari</th>
                      <th>Tanggal Ketemu</th>
                      <th>Dokumen</th>
                      <th className="text-center">Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.nama)}</td>
                          <td>{item.kategori}</td>
                          <td>{item.model}</td>
                          <td>{item.detail}</td>
                          <td>{item.namaPd}</td>
                          <td>{item.namaDesa}</td>
                          <td>
                            {calculateDateDifference(
                              item.createdAt,
                              item.statusProses,
                              item.tanggalStatus,
                              item.model
                            )}
                          </td>

                          <td>
                            {item.modelKonsul == 1 &&
                              formatDate(item.rencanaKetemu)}
                          </td>
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            {item.gambar != "-" && (
                              <img
                                src={domain + item.gambar}
                                alt="Displayed Image"
                                style={{
                                  maxWidth: "100px", // Ubah sesuai dengan lebar maksimum yang diinginkan
                                  maxHeight: "100px", // Ubah sesuai dengan tinggi maksimum yang diinginkan
                                  width: "auto",
                                  height: "auto",
                                  border: "1px solid #ddd", // Menambahkan border untuk estetika
                                  borderRadius: "4px", // Menambahkan radius pada sudut gambar
                                  padding: "5px", // Menambahkan padding
                                  display: "block", // Memastikan gambar ditampilkan sebagai blok
                                  margin: "20px auto", // Menambahkan margin untuk pusat gambar
                                }}
                              />
                            )}
                          </td>
                          <td className="text-center">
                            {item.statusProses
                              ? item.statusProses
                              : "Registrasi"}
                          </td>
                          <td className="text-center">
                            {item.latest_status != "Final" && (
                              <Button
                                color={
                                  item.latest_status === "Finish"
                                    ? "success"
                                    : "primary"
                                }
                                size="sm"
                                onClick={() => {
                                  setId_Unit(item.id);
                                  ambilAnjab(item.id);

                                  setLatest_status(item.statusProses);
                                  setModalOpen(true);
                                }}
                              >
                                Proses
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="lg"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
          color="black"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Tindak Lanjut Pengaduan"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div style={{ backgroundColor: "#333" }}>
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Tanggal</th>
                    <th>Status Proses</th>

                    <th>Catatan</th>
                    <th>Dokumen</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {progressAnjab.map((item) => (
                    <tr>
                      <td>{formatDateTime(item.tanggalStatus)}</td>
                      <td>{item.statusProses}</td>
                      <td>{item.catatan}</td>
                      <td>
                        <div>
                          <img
                            src={domain + item.dokumen}
                            alt="Displayed Image"
                            style={{
                              maxWidth: "100px", // Ubah sesuai dengan lebar maksimum yang diinginkan
                              maxHeight: "100px", // Ubah sesuai dengan tinggi maksimum yang diinginkan
                              width: "auto",
                              height: "auto",
                              border: "1px solid #ddd", // Menambahkan border untuk estetika
                              borderRadius: "4px", // Menambahkan radius pada sudut gambar
                              padding: "5px", // Menambahkan padding
                              display: "block", // Memastikan gambar ditampilkan sebagai blok
                              margin: "20px auto", // Menambahkan margin untuk pusat gambar
                            }}
                          />
                        </div>
                      </td>

                      <td className="text-center">
                        <Button
                          color={
                            item.status === "Finish"
                              ? "warning"
                              : item.status === "Final"
                              ? "success"
                              : "primary"
                          }
                          size="sm"
                          onClick={() => {
                            downloadFile(domain + item.dokumen);
                          }}
                        >
                          download
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  setModalOpen2(true);
                }}
              >
                Proses
              </Button>
              <div style={{ margin: 5 }}></div>
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  setProgressAnjab([]);

                  setModalOpen(false);
                  setId_Unit("");
                  setLatest_status("");
                }}
              >
                Ok
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className="headline-2">{"Upload Dokumen"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Tanggal Proses
              </Label>
              <Col md={9}>
                <Input
                  value={tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                  style={{ color: "black" }}
                  type="date"
                />
              </Col>
            </FormGroup>
            {latest_status === "Finish" ? (
              <div />
            ) : (
              <div>
                <FormGroup row>
                  <Label lg="3" className="text-md-right mt-3">
                    Status
                  </Label>
                  <Col lg="9">
                    <div>
                      <label></label>
                      <Input
                        style={{ color: "black" }}
                        type="select"
                        name="liga"
                        value={status}
                        onChange={(event) => {
                          setStatus(event.target.value);
                        }}
                      >
                        <option value={""}>- pilih -</option>
                        <option value={"Disposisi"}>Disposisi</option>
                        <option value={"Tindak Lanjut"}>Tindak Lanjut</option>

                        <option value={"Finish"}>Finish</option>
                      </Input>
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label md={3} className="text-md-right" for="markdown-editor">
                    Catatan Tindak Lanjut
                  </Label>
                  <Col md={9}>
                    <Input
                      value={catatan}
                      onChange={(e) => setCatatan(e.target.value)}
                      style={{ color: "black" }}
                      type="textarea"
                      rows="4"
                    />
                  </Col>
                </FormGroup>
              </div>
            )}
            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                {
                  "Dokumen atau Foto yang ingin diupload sebagai bukti proses (pdf)"
                }
              </Label>
              <Col lg="9">
                <div>
                  <Input
                    id="upload"
                    type="file"
                    accept={"image/*"}
                    onChange={onChangeDok}
                    style={{ color: "black" }}
                  />
                  <label
                    id="upload-label"
                    htmlFor="upload"
                    className={`font-weight-light text-muted `}
                  >
                    {dokumen ? dokumen.name : "Pilih file"}
                  </label>
                  <div className="input-group-append">
                    <label
                      htmlFor="upload"
                      className="btn btn-light m-0 rounded-pill px-4"
                    >
                      <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                    </label>
                  </div>
                </div>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen2(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ margin: 5 }}></div>
              {saveLoading ? (
                <Button className="btn-rounded" size="sm">
                  {" "}
                  {"Simpan ..."}
                </Button>
              ) : (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Pengaduan;
