export const API_MANEK = "https://aumafada.rotendaokab.go.id/api/";
//'http://localhost:4000/api/';

export const PERANGKAT_DAERAH = API_MANEK + "pd/";
export const ANALISIS_JABATAN = API_MANEK + "anjab/";

export const EFEKTIFITAS = API_MANEK + "efektifitas/";

export const ADMINS_LOGIN = API_MANEK + "admin/login";
export const ADMINS_LOGOUT = API_MANEK + "admin/logout";
export const ADMINS_TOKEN = API_MANEK + "admin/token";
export const POST_ANJAB = API_MANEK + "post/anjab/";
export const POST_PENGADUAN = API_MANEK + "pengaduan/tambah";

export const GET_ANJAB = API_MANEK + "progress/anjab/";

export const ANALISIS_BEBAN_KERJA = API_MANEK + "abk/";
export const POST_ABK = API_MANEK + "post/abk/";
export const GET_ABK = API_MANEK + "progress/abk/";

export const USERS = API_MANEK + "users/";
export const LOG_APP = API_MANEK + "logapp/";
export const GET_NOTIF = API_MANEK + "notifikasi/";
export const GET_AKTIVITY = API_MANEK + "useraktivity/";

export const GET_PERANGKATDAERAH = API_MANEK + "perangkatdaerah/";
export const GET_DESA = API_MANEK + "desa/";
export const GET_PENGADUAN = API_MANEK + "pengaduan/ambil";
export const GET_PENGADUANALL = API_MANEK + "pengaduanAll/ambil";
export const GET_TRACKPENGADUAN = API_MANEK + "trackPengaduan/";
export const POST_TRACKPENGADUAN = API_MANEK + "trackPengaduan/tambah";

export const GET_STATISTIKPENGADUAN = API_MANEK + "statistik/pengaduan";

export const CHECK_KAMIS = API_MANEK + "checkkamis";

export const domain = "https://aumafada.rotendaokab.go.id/dokumen/";

export const API_TIMEOUT = 120000;
